<template>
          <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                 {{ heroTitle }} 
                </h1>
                <p class="mt-4 text-lg text-slate-200">
                  {{ heroDesc }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-slate-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
</template>

<script>
import axios from "axios";

export default {
    data(){
        return{
          heroTitle:null,
          heroDesc:null
        }
    },
    methods: {
    async fetchData() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_ITEM_URL}Hero`,
        {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY,
          },
        }
      );
      this.heroTitle =  response.data.Title;
      this.heroDesc = response.data.Description
    } catch (error) {
      console.error("Error fetching hero item:", error);
    }
  },
},
created(){
  this.fetchData();
}
}
</script>