<template>
  <section id="services" class="pb-20 bg-slate-200">
    <div class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style="transform: translateZ(0)">
      <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon class="text-slate-100 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <div class="container mx-auto">
      <div class="flex flex-wrap items-center">
        <div v-if="mainServiceItem" class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-16">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500">
            <img alt="..." src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80" class="w-full align-middle rounded-t-lg" />
            <blockquote class="relative p-8 mb-4">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="absolute left-0 w-full block h-95-px -top-94-px">
                <polygon points="-30,95 583,95 583,65" class="text-fs-blue fill-current"></polygon>
              </svg>
              <h4 class="text-xl font-bold">{{ mainServiceItem.title }}</h4>
              <p class="text-md font-light mt-2">{{ mainServiceItem.description }}</p>
            </blockquote>
          </div>
        </div>

        <div class="w-full md:w-6/12 px-4">
          <div class="flex flex-wrap">
            <div v-for="(item, index) in services" :key="index" class="w-full md:w-6/12 px-4">
              <div v-if="index % 2 === 0" class="relative flex flex-col mt-4">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                    <i :class="item.icon"></i>
                  </div>
                  <h6 class="text-xl mb-1 font-semibold">{{ item.title }}</h6>
                  <p class="mb-4 text-slate-500">{{ item.description }}</p>
                </div>
              </div>
              <div v-else class="relative flex flex-col min-w-0 mt-4">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                    <i :class="item.icon"></i>
                  </div>
                  <h6 class="text-xl mb-1 font-semibold">{{ item.title }}</h6>
                  <p class="mb-4 text-slate-500">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'ServicesSection',
  data() {
    return {
      services: [],
      mainServiceItem: null
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_CONTENT_URL}services`,
          {
            headers: {
              "api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        const services = response.data;
        this.mainServiceItem = services.find(item => item.mainService === true);
        this.services = services.filter(item => item.mainService !== true);
      } catch (error) {
        console.error("Error fetching services items:", error);
      }
    },
  },
  created() {
    this.fetchData();
  }
};
</script>
