<template>
  <section id="contact" class="relative block py-24 lg:pt-0 bg-slate-800">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-6/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200"
          >
            <div class="flex-auto p-5 lg:p-10">
              <h4 class="text-2xl font-semibold">{{ contact?.title }}</h4>
              <p class="leading-relaxed mt-1 mb-4 text-slate-500">
                {{ contact?.descripton }}
              </p>
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  for="full-name"
                >
                  {{ contact?.form?.name }}
                </label>
                <input
                  v-model="formData.name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="contact?.form?.name"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  {{ contact?.form?.email }}
                </label>
                <input
                  v-model="formData.email"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="contact?.form?.email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  {{ contact?.form?.message }}
                </label>
                <textarea
                  v-model="formData.message"
                  rows="4"
                  cols="80"
                  class="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                  :placeholder="contact?.form?.message"
                />
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="submitForm"
                >
                  {{ contact?.form?.button }}
                </button>
              </div>
              <div 
                v-if="showToast"
                :class="['toast', { 'success': !isError, 'error': isError }]"
              >
                {{ toastMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import DOMPurify from "dompurify";

export default {
  name: "ContactSection",
  data() {
    return {
      contact: null,
      formData: {
        name: "",
        email: "",
        message: ""
      },
      showToast: false,
      toastMessage: "",
      isError: false,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_ITEM_URL}contact`,
          {
            headers: {
              "api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        this.contact = response.data;
      } catch (error) {
        console.error("Error fetching contact item:", error);
      }
    },
    async submitForm() {
      const sanitizedData = {
              name: DOMPurify.sanitize(this.formData.name),
              email: DOMPurify.sanitize(this.formData.email),
              message: DOMPurify.sanitize(this.formData.message),
              read: false
            };
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_ITEM_URL}contactForm`,
          {data:sanitizedData},
          {
            headers: {
              "api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );        
        this.showToast = true;
        this.toastMessage = "Form submitted successfully!";
        this.isError = false;
        this.formData = { name: "", email: "", message: "" };
        setTimeout(() => {
          this.showToast = false;
        }, 5000);
      } catch (error) {
        console.error("Error sending contact form");
        this.showToast = true;
        this.toastMessage = "Error submitting form. Please try again.";
        this.isError = true;
        setTimeout(() => {
          this.showToast = false;
        }, 5000);
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
